import { EventData } from '~/modules/SDK/Events/EventData'
import { eventEmitter } from '~/modules/SDK/Events/eventEmitter'
import { EventString } from '~/modules/SDK/Events/EventString'
import { Socket } from '~/modules/SDK/socket2/Socket'

/**
 * 電腦或手機，從休眠中醒過來時，使 Socket 得以重新連線
 *
 * @example <caption>非 React</caption>
 *   const unsub = socketOnSleepRecoverReconnect({ current: socket })
 *
 *   unsub()
 */
export const socketOnSleepRecoverReconnect = (socketClientRef: {
  current?: Socket.Client | null
}) => {
  const handler = (data: EventData['visibilityChange']) => {
    if (socketClientRef.current?.disconnected && data.state === 'visible') {
      socketClientRef.current?.connect()
    }
  }
  eventEmitter.on(EventString.visibilityChange, handler)

  return function socketOnSleepRecoverReconnectUnsub() {
    eventEmitter.off(EventString.visibilityChange, handler)
  }
}
