import { createStore } from '~/store/createStore'

export type ThemeKey = 'dark' | 'light'

export const useThemeStore = createStore<{
  theme: ThemeKey
}>((set, get) => {
  return {
    theme: 'light',
  }
})
