import { css, Global } from '@emotion/react'
import { Avatar } from '@mui/material'
import { memo, useState } from 'react'
import { BiFullscreen, BiExitFullscreen } from 'react-icons/bi'
import { CgScreenShot } from 'react-icons/cg'
import { flex } from '~/modules/AppLayout/FlexGridCss'
import TemplateTheme from '~/pages/heineken_template/_private/TemplateTheme'

export const WithFullscreenButton = memo<ReactProps>(function WithFullscreenButton(props) {
  const [fullscreen, setFullscreen] = useState(false)

  return (
    <div
      className={props.className}
      data-testid={`fullscreen--${fullscreen}`}
      css={css`
        ${flex.h.default}
        width: 100%;
        height: 100%;
        position: relative;

        ${fullscreen ? fullscreenCSS : notFullscreenCSS};
      `}
    >
      <Global
        styles={css`
          body {
            // 展開到全畫面時，避免出現 scrollbar
            overflow: ${fullscreen ? 'hidden!important' : 'inherit'};
            overflow-y: ${fullscreen ? 'hidden!important' : 'inherit'};
          }
        `}
      ></Global>

      <Avatar
        data-testid='fullscreenButton'
        onClick={() => {
          setFullscreen(prev => !prev)
        }}
        css={css`
          #__next & {
            cursor: pointer;
            position: absolute;
            width: 34px;
            height: 34px;
            bottom: 4px;
            left: 6px;
            color: #59a4e9;
            background-color: #ddf5ff;
            border: 1.5px solid #e8e8e8;

            &:active {
              transform: translate(1px, 1px);
            }
          }

          #__next ${TemplateTheme.classes.Dark} & {
            background-color: #2b2e38;
            border: 1.5px solid #444444;
            &:hover {
              &:hover {
                background-color: #59a4e9;
                color: #ffffff;
                border: 1.5px solid #59a4e9;
              }
            }
          }

          #__next ${TemplateTheme.classes.Light} & {
            background-color: #ffffff;
            &:hover {
              background-color: #59a4e9;
              color: #ffffff;
              border: 1.5px solid #59a4e9;
            }
          }
        `}
      >
        {fullscreen === false && <BiFullscreen />}
        {fullscreen === true && <BiExitFullscreen />}
      </Avatar>
      {props.children}
    </div>
  )
})

const fullscreenCSS = css`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 10000;

  /*
    修正 ios safari 底下那個動來動去的網址列，會造成滿版高設計的投顧，底下老是被蓋住的問題

    Avoid Chrome to see Safari hack

    @see https://github.com/postcss/postcss-100vh-fix
  */
  @supports (-webkit-touch-callout: none) {
    height: fill-available;
  }
`

const notFullscreenCSS = css``
