import { memo } from 'react'
import { createTheme, Theme, ThemeProvider } from '@mui/material'
import { useThemeStore } from '~/components/theme/useThemeStore'

export const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
})

export const lightTheme = createTheme({
  palette: {
    mode: 'light',
  },
})

const ThemeLayout = memo<
  ReactProps<{
    darkTheme?: Theme
    lightTheme?: Theme
  }>
>(function ThemeLayout(props) {
  const theme = useThemeStore(t => t.theme)

  return (
    <ThemeProvider
      theme={theme === 'dark' ? props.darkTheme ?? darkTheme : props.lightTheme ?? lightTheme}
    >
      {props.children}
    </ThemeProvider>
  )
})

export default ThemeLayout
