import styled from '@emotion/styled'
import { MantineProvider, Tooltip, TooltipProps } from '@mantine/core'
import { Paper } from '@mui/material'
import { memo } from 'react'
import ThemeLayout from '~/components/theme/ThemeLayout'
import { useThemeStore } from '~/components/theme/useThemeStore'
import { clas } from '~/utils/clas'

const classes = {
  Root: styled.div`
    height: 100%;
  `.withComponent(Paper),
  Light: styled.div``,
  Dark: styled.div``,
}

const Display = memo<ReactProps>(function Display(props) {
  const themeMode = useThemeStore(state => state.theme)

  return (
    <MantineProvider
      theme={{
        colorScheme: themeMode,
        components: {
          Tooltip: {
            defaultProps: {
              withArrow: true,
            } as Partial<TooltipProps>,
          },
        },
      }}
    >
      <ThemeLayout>
        <classes.Root
          data-test-id='TemplateTheme'
          data-test-theme-mode={themeMode}
          elevation={0}
          square
          className={clas([
            themeMode === 'dark' && classes.Dark,
            themeMode === 'light' && classes.Light,
            props.className,
          ])}
        >
          {props.children}
        </classes.Root>
      </ThemeLayout>
    </MantineProvider>
  )
})

export default { Display, classes }
