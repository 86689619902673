import clsx from 'clsx'

/**
 * # 與 npm:clsx 功能相同
 *
 * 不同在於，若傳入 const Button = styled.div``
 *
 * Clas([Button]) 會返回不對的，不是我要的 className string
 *
 * 若 clas([String(Button)]) 則會多返回一個「點」例如「.e2dw6ww1」
 *
 * 目的是把那個「點」去掉
 *
 * @example
 *   const classes = {
 *     Button: styled.div`
 *       display: flex;
 *       width: 100%;
 *       place-content: center;
 *       align-items: center;
 *       text-align: center;
 *       cursor: pointer;
 *       user-select: none;
 *     `,
 *     ButtonOfActive: styled.div``,
 *     Inner: styled.div``,
 *   }
 *
 *   const TestComponent: React.FC = props => {
 *     const isActive = true
 *
 *     return (
 *       <classes.Button className={clas([props.className, isActive && classes.ButtonOfActive])}>
 *         <classes.Inner>{props.children}</classes.Inner>
 *       </classes.Button>
 *     )
 *   }
 */
export const clas = (args: any | any[]) => {
  if (!Array.isArray(args)) {
    /**
     * 判斷出 arg 是不是「styled.div``」如果是
     *
     * 將它 toString() 會多出一個點「.」把它去掉
     */
    if (args?.__emotion_real) return String(args).slice(1)

    return args
  }

  return clsx(
    args.map(arg => {
      /**
       * 判斷出 arg 是不是「styled.div``」如果是
       *
       * 將它 toString() 會多出一個點「.」把它去掉
       */
      if (arg?.__emotion_real) return String(arg).slice(1)

      return arg
    }),
  ).replace(/\./gim, '')
}
