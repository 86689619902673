import NoSleep from 'nosleep.js'
import { useCallback, useEffect, useRef } from 'react'
import { useIsMobile } from '~/hooks/useIsMobile'
import { debugAPI } from '~/modules/SDK/debug/debugAPI'

/**
 * Disable screen sleep on mobile devices
 *
 * ## No Supports in SSR/SSG, should be import by dynamic
 */
export const useNoSleepMobile = () => {
  const isMobile = useIsMobile()
  const noSleepRef = useRef<NoSleep>()

  const enable = useCallback(() => {
    document.addEventListener(
      'click',
      function enableNoSleep() {
        noSleepRef.current = new NoSleep()
        document.removeEventListener('click', enableNoSleep, false)
        noSleepRef.current.enable()
      },
      false,
    )
    log('enable')
  }, [noSleepRef])

  useEffect(() => {
    const noSleep = noSleepRef.current

    if (isMobile) {
      document.addEventListener('click', enable, false)
      enable()
    } else if (!isMobile) {
      noSleep?.disable()
      log('disable')
    }

    return () => {
      noSleep?.disable()
      log('disable')
    }
  }, [enable, isMobile, noSleepRef])
}

const log = debugAPI.extend(useNoSleepMobile.name)
